import { render, staticRenderFns } from "./Danhsachvaitro.vue?vue&type=template&id=c47a3574&scoped=true&"
import script from "./Danhsachvaitro.vue?vue&type=script&lang=js&"
export * from "./Danhsachvaitro.vue?vue&type=script&lang=js&"
import style0 from "./Danhsachvaitro.vue?vue&type=style&index=0&id=c47a3574&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c47a3574",
  null
  
)

export default component.exports